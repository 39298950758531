<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="submitForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <!-- Role Name -->
                <WeInput
                  label="Rol Adı"
                  v-model="name"
                  name="role-name"
                  v-bind:required="true"
                  v-bind:error="$v.name.$error"
                />
                <!-- ./Role Name -->

                <Role
                  v-bind:items="role.source"
                  v-bind:role-type="role.roleType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import Role from "./components/Role/Index";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: true,
      name: null,
      errors: [],
    };
  },
  validations: {
    name: {
      required,
    },
  },
  components: {
    Role,
    Navigation,
  },
  methods: {
    ...mapActions("role", ["getRoleModels", "create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    getRoleById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.name = result.name;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      }
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateRole();
      } else {
        this.addRole();
      }
    },
    addRole() {
      this.create({
        name: this.name,
        source: this.role.source,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Roller listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateRole() {
      this.update({
        id: this.$route.params.id,
        name: this.name,
        source: this.role.source,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Roller listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/roles");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["role", "shared"]),
    getTitle() {
      return this.$route.params.id ? "ROL DÜZENLE" : "ROL EKLE";
    },
    checkPermission() {
      return permission.check("roleGroup", "u");
    },
  },
  mounted() {
    this.ready = false;
    this.getRoleModels({
      onFinish: () => {
        this.getRoleById();
        this.ready = true;
      },
    });
  },
};
</script>
