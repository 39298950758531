<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <tr>
        <td>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="all-priv"
              v-bind:checked="superAdmin"
              v-on:change="setAllPriv"
            />
            <label class="custom-control-label" for="all-priv"
              >Tüm İzinleri Ver</label
            >
          </div>
        </td>
        <!-- Role Types Header -->
        <th
          class="bg-light"
          v-for="type in roleType"
          v-bind:key="type.name"
          scope="col"
        >
          <div class="row">
            <div class="col">{{ type.name }}</div>
            <div class="col-auto">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-bind:id="'header-' + type.name"
                  v-bind:checked="getAllPriv(type.code)"
                  v-on:change="setAllPriv($event, type.code)"
                />
                <label
                  class="custom-control-label"
                  v-bind:for="'header-' + type.name"
                ></label>
              </div>
            </div>
          </div>
        </th>
        <!-- Role Types Header -->
      </tr>
      <tr v-for="role in items" v-bind:key="role.title">
        <!-- Role Header -->
        <th scope="row" class="bg-light text-left">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              v-bind:checked="unAuthorized(role)"
              v-on:change="toggleAllChecked(role, $event.target.checked)"
              v-bind:id="titleSlug(role.title)"
            />
            <label
              class="custom-control-label"
              v-bind:for="titleSlug(role.title)"
              >{{ role.title }}</label
            >
          </div>
        </th>
        <!-- Role Header -->
        <td
          class="text-center"
          v-for="(type, i) in roleType"
          v-bind:key="i"
          scope="col"
        >
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              v-bind:id="getInputValues(role.title, type.code)"
              v-on:change="
                setCheckedValue(role, type.code, $event.target.checked)
              "
              v-bind:checked="getCheckedValue(role, type.code)"
            />
            <label
              class="custom-control-label"
              v-bind:for="getInputValues(role.title, type.code)"
            ></label>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "Role",
  data() {
    return {
      superAdmin: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    roleType: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getCheckedValue(role, code) {
      return role.code.includes(code);
    },
    setCheckedValue(role, code, checked) {
      const codeIndex = role.code.findIndex((rc) => rc == code);

      if (!checked) {
        role.code.splice(codeIndex, 1);
        if (!role.code.length) {
          this.setUnAuthorized(role);
        }
      } else {
        role.code.push(code);
        this.clearUnAuthorized(role);
      }
    },
    toggleAllChecked(role, checked) {
      if (checked) {
        role.code = this.roleType.map((key, value) => {
          if (key.code !== "n") {
            return key.code;
          }
        });
        this.superAdmin = true;
      } else {
        this.setUnAuthorized(role);
      }
    },
    unAuthorized(role) {
      return role.code.indexOf("n") < 0;
    },
    setUnAuthorized(role) {
      role.code = ["n"];
      this.superAdmin = true;
    },
    clearUnAuthorized(role) {
      const index = role.code.findIndex((code) => code == "n");
      if (index > -1) {
        role.code.splice(index, 1);
      }
    },
    setAllPriv(e, code) {
      const checked = e.target.checked;
      for (let i = 0; i < this.items.length; i++) {
        const role = this.items[i];
        if (code) {
          this.setCheckedValue(role, code, checked);
        } else {
          this.toggleAllChecked(role, checked);
        }
      }
    },
    getAllPriv(code) {
      let selectedCodes = [];

      if (this.items) {
        for (let i = 0; i < this.items.length; i++) {
          const role = this.items[i];

          for (let j = 0; j < role.code.length; j++) {
            const element = role.code[j];
            if (selectedCodes.indexOf(element) < 0) {
              selectedCodes.push(element);
            }
          }
        }
        return selectedCodes.includes(code);
      }
    },
    titleSlug(title) {
      return helper.slugify(title);
    },
    getInputValues(title, code) {
      return this.titleSlug(title) + "-" + code;
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  li {
    list-style-type: none;
  }
}
tr:hover {
  td {
    background-color: #f8f9fa;
  }
}
</style>
